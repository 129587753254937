// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'
import {Link
} from "react-router-dom";

import axios from 'axios'
import TelegramLoginButton from 'react-telegram-login';
import { API_URL } from '../var'
import { Toggle } from './ui/toggle';
const Wrapper = styled.div`
padding: 4rem 0;
display:flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
flex-grow: 1;
height: 100vh;
`
const Logo = styled.div`
height:200px;
width: 250px;
background-repeat: no-repeat;
background-size: contain;
background-image: url('./logo.jpg');
box-sizing: border-box;
`
const Menu = styled.div`
display:flex;
`
const P = styled.p`
text-decoration: none;
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
padding: 10px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 768px) {
  font-size: 0.7em;
  }
@media screen and (max-width: 420px) {
    font-size: 0.6em;
    }
`
const Div = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const Div2 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const Login = ({ setAuth, setSelectedTheme }) => {


 
  
      
  const auth = async ({
    auth_date,
    first_name,
    hash,
    id,
    last_name,
    photo_url,
    username }) => {
    
    await axios.post(API_URL + '/check', 
           { auth_date,
            first_name,
            hash,
            id,
            last_name,
            photo_url,
            username } ///123456 или 765434
    ).then(res => {
      let {idc, registred, role }  = res.data
          setAuth({
            idc, registred, role, auth_date,
            first_name, photo_url,
            username
          })
        }
        ).catch(err=>console.error(err) )
  }
  var bot = process.env.NODE_ENV !== 'production'?"vediastro_bot":"sundaria_bot"
 
    return (
      <Wrapper>
        <Toggle id='login' setSelectedTheme={setSelectedTheme}/>
        <Menu>
          <Link to={"/about"}>  <P>Über Yelena Sundaria</P></Link>

           <P>|</P>
<Link to={"/programms"}><P>Wie fange ich an?</P></Link>
          
       
        </Menu>
        <Logo />
        <Div>
          {/* <TelegramLoginButton dataOnauth={auth} botName={bot} />
          <P style={{textAlign: 'center'}}>Die Freischaltung für die Lernplattform erfolgt über Telegram.</P> */}
        </Div>
<Div2>
        <a href={"https://t.me/sundariagespraeche"}> <P style={{textAlign: 'center'}}>Hier geht es zum Chat @sundariagespraeche</P></a>
          <P style={{ textAlign: 'left' }}>{
            `Kleine Jyotish-Chart umfasst:
Umfangreiche Antwort und Analyse auf 3 wichtige Lebensfragen
Eine 1,5-stündige Online-Konsultation
Google-Dokument zum Lesen
Audiodatei zum Herunterladen
Kosten: 180 EUR / 170 Franken

Große Jyotish-Chart umfasst:
Antwort auf unbegrenzte Anzahl von Fragen
Eine 2- bis 2,5-stündige Konsultation
Google-Dokument zum Lesen
Audiodatei zum Herunterladen
Kosten: 250 EUR / 240 Franken`}</P>
            <P style={{ textAlign: 'center' }}></P>
          <P style={{ textAlign: 'center' }}>{`Kontaktdaten: Yelena WhatsApp: +49 151 20233814 E-Mail: alenavedica@gmail.com`}</P>
         </Div2>

</Wrapper>
    )
}


